*{
  padding:0;
  margin: 0;
}

.header-clr{
  color: #173b6c;
}

.hero-img{  
  overflow: hidden;
}

@media (max-width:376px){
  .hero-img{
    width: 100%;
  }
}

.divider{
  height: 5px;
  width:50px;
  display: block;
  background-color: #149DDD;
  line-height:10px;
}

.AboutMain{
  display: flex;
  margin-bottom: 40px;
}

.picture{
  max-width: 45%;
  margin-right: 25px;
}

.bio h3{
  color: #173b6c;
}

.main-bio{
  display: flex;
}

.arrow-clr i{
  color: #a5c5d3;
}

.left-bio{
  margin-right: 80px;
  margin-top: 10px;
}

.right-bio{
  margin-top: 10px;
}

.bio-ptag{
  margin-top: -20px;
}

@media (max-width:769px){
.AboutMain{
  display: grid;
}  
.picture{
  width: 400px;
  margin-bottom: 10px;
}
.main-bio{
  display: grid;
}
.right-bio{
  margin-top: 0px;
}
.dp-pic{
  width: 500px;
  height: 530px;
}
}

@media (max-width:426px){
.dp-pic{
  width: 410px;
  height: 430px;
}
}

@media (max-width:376px){
  .dp-pic{
    max-width: 340px;
    max-height: 370px;
  }
}

@media (max-width:321px){
  .dp-pic{
    max-width: 290px;
    max-height: 310px;
  }
}

@media (max-width:376px){
  .bio{
    display: grid;
    max-width: 350px;
    padding-bottom: 30px;
}
}

@media (max-width:321px){
  .bio{
    display: grid;
    max-width: 300px;
    padding-bottom: 30px;
}
}

.skill-container{
  background-color:#e9ecef;
  margin-bottom: 50px;
  padding-top: 10px;
}

.skill-main{
  display: flex;
  width: 100%;
  padding-bottom: 30px;
}

.skill-left{
  width: 45%;
  margin-right: 100px;
}

.skill-right{
  width: 45%;
}

.skill-p{
  margin-top: -10px;
}

@media(max-width:769px){
  .skill-main{
    display: grid;
    width: 100%;
    padding-bottom: 30px;
  }
  .skill-left{
    margin-bottom: 15px;
    width: 100%;
  }
  .skill-right{
    width: 100%;
  }
}

/*Resume*/

.list-wrapper {
  position:relative;
}

.list-item-wrapper {
  margin-top:11px;
  position:relative;
}

.list-bullet {
  float:left;
  margin-right:21px;
  background:#173b6c;
  height:31px;
  width:31px;
  line-height:31px;
  border-radius:100px;
  font-weight:701;
  color:white;
  text-align:center;
}

.list-item {
  display:table-row;
  vertical-align:middle;
}

.list-title {
  font-weight:701;
}

.list-text {
  font-weight:401;
}

.red-line {
  background:#173b6c;
  z-index:-2;
  width:3px;
  height:100%;
  position:absolute;
  left:16px;
}

.white-line {
  background:pink;
  z-index:-2;
  top:0px;
  width:2px;
  height:100%;
  position:absolute;
  left:16px;
}

.resume-main{
  display: flex;
}

.resume-left{
  width: 70%;
}

.year-span{
  width: 120px;
  height: 30px;
  background-color: #E4EDF9;
}

@media(max-width:769px){
  .resume-main{
    display: grid;
  }
  .resume-left{
    width: 100%;
  }
}

#portfolio{
  background-color: #e9ecef;
  padding-top: 10px;
  margin-bottom: 20px;
}

.card{
  width: 25rem;
}

.card:hover {
  transform: scale(1.1);
}
.card {
  transition: transform 0.5s;
}

.card-main{
  display: flex;
  justify-content: space-around;  
  padding-bottom: 20px;
}

@media(max-width:769px){
  .card-main{
    display: grid;
    justify-content: left;
  }
  .card{
    margin-bottom: 20px;
    margin-left: 2px;
  }
  .card:hover {
    transform: scale(1.1);
  }
  .card {
    transition: transform 0.5s;
  }
}

@media (max-width:376px){
  .card{
    width: 21rem;
  }
}

@media (max-width:321px){
  .card{
    width: 18rem;
  }
}

.form-div{
  background-color: #d2d5d7;
  padding: 20px;
  margin-bottom: 50px;
}

.hero-main{
  width: 100%;
  height: 500px;
  background-image: url("../src/Images/online-certification.jpeg.webp");
  filter: blur(8px);
  -webkit-filter: blur(8px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-text {
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  position:relative;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 50%;
  padding: 20px;
  text-align: center;
  margin-top: -250px;
  margin-bottom: 100px;
}

@media(max-width:426px){
  .bg-text{
    margin-top: -250px;
    margin-bottom: 0px;
  }
}

  textarea {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);

    &:focus {
      border: 2px solid rgba(0, 206, 158, 1);
    }
  }

  